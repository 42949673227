import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const RisksOfCampaignImplementation = ({ location }) => {
  const title = "プレゼントキャンペーンを社内で実施するリスクとその回避方法"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="プレゼントキャンペーンの社内実施におけるリスクと回避方法 | マーケ担当者必見"
          pagedesc="プレゼントキャンペーンを社内で実施する場合の見えないリスクとその回避方法について、システム化・業務効率化の費用感を交えて解説します。おすすめSaaSサービスも。SNSを含むマーケティング担当者必見です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/risks-of-campaign-implementation.jpg"
          pagepath="/blog/risks-of-campaign-implementation"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>プレゼントキャンペーンを社内で実施するリスクとその回避方法</h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年5月11日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ジェンガをしている男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/risks-of-campaign-implementation.jpg"
                className="w-full"
              />
            </figure>
            <p>
              こんにちは、PARKLoT代表の松田信介です！
              <br />
              本日は、プレゼントキャンペーンを社内で実施する場合の見えないリスクと、その回避方法についてお伝えします。
              <br />
              <br />
            </p>
            <h2>認知拡大にはプレゼント企画！しかしリスクも見過ごせない</h2>
            <p>
              懸賞はがきやプレゼント企画などのキャンペーンは、自社の商品やブランドを多くの潜在顧客に認知してもらい、ファンを増やすマーケティング手法として、多くの企業が取り入れています。
              <br />
            </p>
            <p>
              しかしながら、
              <strong>
                自社でキャンペーンを運用すると、思わぬリスクが潜んでいる
              </strong>
              こともあります。
              <br />
            </p>
            <p>
              最初に結論を述べますと、様々なリスクを回避するには、
              <strong>
                キャンペーンに関わる業務がシステムにより「自動化」され、作業の過程が「データ保存」され、問題が発生しないように「監視機能」があるサービスを利用する
              </strong>
              ことをお勧めします。
              <br />
              <br />
            </p>
            <p>
              この記事では、どのようなリスクが存在し、そのリスクを回避するためにはどんな対策が必要か、対策を実現するための手段について、深掘りしています。
              <br />
            </p>
            <p>特に読んでいただきたいのは、下記に当てはまる方々です。</p>
            <p>
              <br />
              社内でプレゼントキャンペーンを実施している企業で...
            </p>
            <ul>
              <li>抽選業務が景品表示法に抵触しないか神経を尖らせている</li>
              <li>利用者からの抽選方法などの具体的な問合せに返信できない</li>
              <li>
                チェック体制を整えているが、人手が掛かるため作業の効率化や運用コストを減らしたい
              </li>
              <li>業務が属人化しており、ノウハウを社内に共有したい</li>
            </ul>

            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1.抽選作業を社内で実施する際の見えないリスク
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・タスク増、人手不足でミス発生
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・情報漏洩や企業への大きなダメージの恐れも
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    2.リスクを回避するための対策
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・システム化してミスも負担も一挙に減らす
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・記録化してPDCAを回す
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・透明性・公平性・レスポンス速度を上げる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・監視機能を強化する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    3.対策を行うためのシステム利用方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・自社エンジニアによる構築
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・システム開発企業に外注
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・SaaSの活用
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    4.リスクを減らすPARKLoTの特徴
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ・システムによる自動化
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        ・プロセスを担保するデータ保管
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        ・万が一に備えた監視機能
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id17" className="toc__link">
                    5.まとめ 顧客のためにも会社のためにも、リスク対策は必須
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id18" className="toc__link">
                    自社のアカウントでも試してみたい方に
                  </a>
                </li>
              </ol>
            </div>

            <div>
              <h2 id="id1">1.抽選作業を社内で実施する際の見えないリスク</h2>
              <p>
                まずは、プレゼントキャンペーンを社内で行う場合に発生するリスクをご説明します。
                <br />
              </p>
              <h3 id="id2">タスク増、人手不足でミス発生</h3>
              <p>
                キャンペーン企画の実施には企画から景品購入、抽選作業、当選者への連絡、景品の発送、応募者・当選者情報の管理など多岐に渡る業務があります。
                <br />
              </p>
              <p>
                多くの企業では、キャンペーン企画業務を自社の社員に任せています。{" "}
                <br />
                社員が多く在籍し、チェック体制が整っていれば問題ありませんが、中小企業などは人手を割くことができないのが現状です。
                <br />
              </p>
              <p>
                そして、どのような作業にもヒューマンエラーはつきものです。
                <br />
                例えば、当選者への連絡ミス、景品の送り先間違いといった人為的なミスが発生しがちです。
                <br />
              </p>
              <h3 id="id3">情報漏洩や企業への大きなダメージの恐れも</h3>
              <p>
                また、応募者の個人情報を預かることになり、管理が徹底していなければ、紛失や流出といった事態にまで発展しかねません。
                <br />
                最悪のケースとして、当選者数の水増しや景品の着服、金品の横領なども起こり得ます。
                <br />
              </p>
              <p>
                このような事態が発生すると、社外に対しても大きなリスクとなります。
                <br />
              </p>
              <p>
                消費者庁から景品表示法違反の疑いとして調査や悪質な場合は指導を受けることになり、商品や企業に対して、
                <strong>ネガティブな評判やブランドイメージの毀損</strong>
                に繋がります。このような不祥事が日本各地の企業で発生し、ニュースでも報道されているのは皆様もご存知だと思います。
                <br />
              </p>
              <p>
                このように、人手を介したキャンペーン作業は、運用コストが掛かる上に不正の温床になり、ブランド価値を下げうるリスクがいくつも隠れています。
                <br />
              </p>
            </div>

            <div>
              <h2 id="id4">2.リスクを回避するための対策</h2>
              <p>
                これらのリスクを回避するには、どのような対策を行えばよいでしょうか。
                <br />
                以下の4点についてご説明します。
                <br />
              </p>

              <h3 id="id5">システム化してミスも負担も一挙に減らす</h3>
              <p>
                抽選や当選連絡などの
                <strong>
                  キャンペーンに関わる一連の業務を人手が入らないシステムに任せることで人為的なミスを減らす
                </strong>
                ことができます。
                <br />
              </p>
              <p>
                さらに、業務のシステム化は<strong>属人性の排除</strong>
                に繋がります。
                <br />
                一部の担当者に偏っていた業務が、システム化によって業務フローが構築され、ある程度誰でも業務遂行が可能になり、業務委託や引き継ぎがしやすくなります。
                <br />
              </p>
              <p>
                従来、景品購入や応募者名簿管理、抽選作業、当選連絡などの業務に当てていた担当者のリソースを、商品の開発や顧客対応など本来あるべき業務に集中させることができます。
                <br />
              </p>

              <p>
                さらに、インターネット経由でシステムを利用できれば、場所や時間を問わずリモートでも業務を行うことができ、業務効率化に繋がります。
                <br />
              </p>

              <h3 id="id6">記録化してPDCAを回す</h3>
              <p>
                キャンペーン企画への応募者・当選者情報、SNSマーケティングであればフォロワー増加数、クリエイティブや景品・クーポン券・Amazonギフト券の組み合わせ・キャンペーン実施日数、運用によって発生するユーザーとのコミュニケーション密度や頻度、締め切りまでの告知方法など、すべてのデータを記録・保管し、参照しやすくしておくと、ノウハウとして参照する価値があるデータとなります。
                <br />
              </p>
              <p>
                成功したときやうまくいかなかったケースのデータを把握できるように記録しておくことで、社内で分析し、次の改善に生かせます。
              </p>
              <p>
                つまり、
                <strong>
                  データをもとに、コミュニケーションを行うことができ、意思決定がしやすく
                </strong>
                なります。
              </p>
              <p>
                このように、従来一部の担当者が行っていた業務やキャンペーン企画の結果を、記録化しデータにすることで、キャンペーン企画業務をDX(デジタルトランスフォーメーション)に繋げることができ、
                <strong>
                  ノウハウが蓄積された自社企業だけのナレッジデータベースの構築
                </strong>
                が可能になります。
                <br />
              </p>
              <p>
                また、誰にいつ当選通知を行ったかをすべて記録しておくことは抽選作業を行う上で重要です。
              </p>
              <p>
                社内の管理体制として、記録をもとに上司など第三者によるチェックを行うことができます。
                <br />
              </p>
              <p>
                また、この記録を持って当選の証明とすることができ、当選連絡が来ているのに商品が一向に来ない、応募した記憶がないのに当選連絡がきたなどの問い合わせにもデータを遡ることで、原因の究明が可能になり、利用者から問い合わせに回答しやすくなり、顧客対応の満足度向上にも貢献できます。
              </p>

              <h3 id="id7">透明性・公平性・レスポンス速度を上げる</h3>
              <p>
                記録化と同様に、システム化することで、抽選の過程や当選のロジックがいつでも同じになり、透明性や公平性を担保します。
                <br />
              </p>
              <p>これにより、担当者による恣意的な操作が難しくなります。</p>
              <p>
                また、キャンペーン企画の説明にありがちな「当選者の発表は賞品の発送をもってかえさせていただきます」を用いてしまうと、当選し商品が届いた時には応募したことも忘れてしまっている可能性もあります。
              </p>
              <p>
                <strong>
                  透明性や公平性があるプロセスを持ち、応募に対して結果をすぐに伝えることができる仕組み
                </strong>
                があると、利用者にとって
                <strong>サービス利用への安心や期待感の醸成</strong>
                に繋がります。
              </p>

              <h3 id="id8">監視機能を強化する</h3>
              <p>
                システム化によるデメリットとしては、不正アクセスやデータ改竄などの外部からの攻撃の可能性が増える点です。
                <br />
              </p>
              <p>
                そのため、外部企業へ監視業務の委託や社内にシステムに詳しいセキュリティ担当者を設置する必要があります。
                <br />
                常時システムに問題がないかを監視し、万が一問題が生じてしまった際、
                <strong>原因を特定し、対応できる仕組み</strong>
                が重要となります。
                <br />
              </p>
              <p>
                一連の作業プロセスが記録されており、追跡しやすいと、
                <strong>問題の切り分けや責任の所在を明らかに</strong>できます。
              </p>
              <p>
                一部の担当者に業務が属人化していると、不在の場合の初動の遅れやヒアリングなど原因究明に時間を要し、顧客満足度の低下に繋がりかねません。
              </p>
            </div>

            <div>
              <h2 id="id9">3.対策を行うためのシステム利用方法</h2>
              <p>
                上記に挙げた対策を行うには、システムエンジニアによって設計・開発されたシステムを利用する必要があります。
              </p>
              <p>
                そのためには、①自社エンジニアによる構築、②システム開発企業に外注、③SaaSの活用という3つの手段があります。
              </p>
              <p>
                それぞれ、予算・納期・カスタマイズ性・ノウハウの溜まりやすさという点で詳しくみていきましょう。
              </p>
              <h3 id="id10">自社エンジニアによる構築</h3>
              <p>
                社内にエンジニアがいる場合、キャンペーン業務の担当者の業務内容やノウハウを要件定義書に落とし込むことで、自社に最適なシステム構築が可能です。
              </p>
              <p>
                自社でキャンペーン企画を実施すればするほど、データ化されたノウハウが溜まっていきます。
              </p>
              <p>
                予算の点では、リソース配分を行うことで調整がしやすい利点があります。
              </p>
              <p>
                システムの品質としても、少しずつ機能を開発し検証するアジャイル開発が可能になり、自社に最適なシステムの構築ができます。
              </p>
              <p>一方で、リソース配分によっては完成まで時間を要しがちです。</p>

              <h3 id="id11">システム開発企業に外注</h3>
              <p>
                自社エンジニアを抱えていない企業もあります。その場合は、システム開発会社に委託することになります。
              </p>
              <p>
                期限を決めた上で、自社に合った仕様の策定から開発、検証を行うことで高品質なシステムが利用可能となります。さらには、自社の他のシステムとの連携や業務フローに沿ったシステム化など多様なことができます。
              </p>
              <p>
                一方、予算の点では高くつき、数百万円以上と大規模な開発費用が掛かってしまうこともあります。追加機能の開発にさらなる費用増や、使い勝手が悪かった場合は一からやり直し、なんてことにもなりかねません。
              </p>
              <p>
                システム開発会社が入ることで、システム化などの部分がブラックボックス化し、ノウハウは溜まりにくい傾向にあります。
              </p>

              <h3 id="id12">SaaSの活用</h3>
              <p>
                すでに出来上がっているシステムを必要な機能だけ利用できるSaaSを活用すると、システム開発なしに、低コストで利用を開始できます。
              </p>
              <p>
                SNSマーケティングの専門家が開発しているため、他企業が成功してきたノウハウも提供されているケースが多くあります。必要に応じ追加料金を払うことで、より高機能な機能の利用が可能になります。
              </p>
              <p>
                デメリットとしては、自社への完全なカスタマイズが難しい点です。
              </p>
              <p>表としてまとめたものが以下になります。</p>

              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th className="blog__text-center">予算</th>
                    <th className="blog__text-center">納期</th>
                    <th className="blog__text-center">カスタマイズ性</th>
                    <th className="blog__text-center">ノウハウ蓄積</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="blog__text-center">自社エンジニア構築</td>
                    <td className="blog__text-center">
                      △<br />
                      社内リソース調整次第
                    </td>
                    <td className="blog__text-center">
                      △<br />
                      社内リソース調整次第
                    </td>
                    <td className="blog__text-center">
                      ◎<br />
                      自社に最適化
                    </td>
                    <td className="blog__text-center">
                      ◯<br />
                      自社内で溜まりやすい
                    </td>
                  </tr>
                  <tr>
                    <td className="blog__text-center">
                      システム開発会社に外注
                    </td>
                    <td className="blog__text-center">
                      ×<br />
                      大規模予算
                    </td>
                    <td className="blog__text-center">
                      ◯<br />
                      コントロールしやすい
                    </td>
                    <td className="blog__text-center">
                      ◎<br />
                      自社に最適化
                    </td>
                    <td className="blog__text-center">
                      △<br />
                      外注のため溜まりにくい
                    </td>
                  </tr>
                  <tr>
                    <td className="blog__text-center">SaaS活用</td>
                    <td className="blog__text-center">
                      ◎<br />
                      低予算
                    </td>
                    <td className="blog__text-center">
                      ◎<br />
                      即日利用可能
                    </td>
                    <td className="blog__text-center">
                      ◯<br />
                      必要機能のみ
                    </td>
                    <td className="blog__text-center">
                      ◎<br />
                      他企業のノウハウを活用可能
                    </td>
                  </tr>
                </tbody>
              </table>
              <small>※各記号は、◎…優れている　◯…良い　△…微妙　×…良くない</small>
            </div>

            <div>
              <h2 id="id13">リスクを減らすPARKLoTの特徴</h2>
              <p>
                上記で挙げたリスク対策が可能なSaaSとして、PARKLoTの特徴をご紹介します!
              </p>
              <h3 id="id14">システムによる自動化</h3>
              <p>
                PARKLoTでは、キャンペーンシステムの抽選から当選連絡までの作業はプログラムが自動で行い、人手を掛ける必要がありません。
              </p>
              <p>
                そのため、
                <strong>人為的なミスや不正が限りなく起きにくい仕組み</strong>
                となっています。
              </p>
              <p>
                例えば、サービスにログインしたアカウントのみ抽選対象者とする、当選者は必要情報をDM経由で記載した人にのみ行うなど、対象者を判別するシステムがあります。
              </p>
              <p>
                PARKLoTでは、システムをウェブサービスとして提供しており、膨大な予算をかけてシステム構築する必要なく、月額55,000円(税込)〜という低コストでサービスを利用することができます。
              </p>

              <h3 id="id15">プロセスを担保するデータ保管</h3>
              <p>
                利用者からの問い合わせや問題の発覚はいつ生じるかわからないものです。PARKLoTでは、キャンペーンに関わる全ての作業(プログラムの実行ログ)をシステム上で記録しており、データベースで保管しています。
              </p>
              <p>
                必要に応じて、この
                <strong>
                  記録を使って検証、原因究明をすることができ、いざというときも安心
                </strong>
                です。
              </p>
              <p>
                また、データは全て暗号化された上でクラウド上に保管されており、セキュリティ対策も万全です。
              </p>
              <a
                href="https://aws.amazon.com/jp/compliance/data-privacy-faq/"
                target="_blank"
                rel="noreferrer"
              >
                参考：データプライバシー – アマゾンウェブサービス (AWS)
                (amazon.com)
              </a>

              <h3 id="id16">万が一に備えた監視機能</h3>
              <p>
                実行ログの保管先として利用しているAWSのストレージサービスには、バージョニング(世代管理)機能が備わっており、
                <strong>
                  外部からの不正アクセスに対しても有効に機能する監視機能
                </strong>
                を持っています。
              </p>
              <p>
                PARKLoT
                を運営・管理する弊社では、アクセス権限の設定を徹底し、管理者数名しかデータベースにアクセスできません。また、操作時は全て操作ログが残ります。
              </p>
              <p>
                このように、システム自体が堅牢に作られており、安心して利用できます。
              </p>
              <a
                href="https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/userguide/Versioning.html"
                target="_blank"
                rel="noreferrer"
              >
                参考:S3 バケットでのバージョニングの使用 - Amazon Simple Storage
                Service
              </a>
            </div>

            <div>
              <h2 id="id17">
                5.まとめ 顧客のためにも会社のためにも、リスク対策は必須
              </h2>
              <p>
                このように、自社内でキャンペーンを実施する際は、多くの見えないリスクが存在します。
              </p>
              <p>
                <strong>
                  利用者を安心させ、ブランドを守り、発展させていく
                </strong>
                ためにも、専門家が開発した、
                <strong>
                  キャンペーンに関わる業務がシステムによって「自動化」され、作業の過程が「データとして保存」され、問題が発生しないように「監視機能」があるサービスを利用する
                </strong>
                ことをお勧めします!
                <br />
              </p>
              <p>
                PARKLoTは少数精鋭のエンジニアのみで構成されたチームで運営しているため、低価格かつ高品質なサービスをお届けすることが可能となっています。PARKLoTの導入を、ぜひご検討ください!
              </p>
            </div>

            <h2 id="id18">自社のアカウントでも試してみたい方に</h2>

            <p>
              PARKLoTでは、「インスタントウィンキャンペーンはPDCAサイクルを回して実施することが重要」だと考えています。お客様に、SNSマーケティングの効果を実感していただきたい！という思いから、満足できる機能を低価格でご提供しております。
            </p>
            <p>
              この記事を読んで、自社の公式アカウントでも、さまざまなキャンペーンを繰り返し実施したいと思われた方は、お気軽にお問い合わせください！
            </p>
            <p>
              初めてPARKLoTをご利用いただく方には、無料で1時間のキャンペーン企画のご相談を承っております。料金の詳細や機能の説明も含め、貴社に最適なキャンペーンのご提案をさせていただきます。
            </p>
            <a
              href="https://www.park-lot.com/download/service"
              target="_blank"
              rel="noreferrer"
            >
              お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
            </a>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="matsuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default RisksOfCampaignImplementation
